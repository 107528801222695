import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import ReportHeader from "./report-header/report-header";
import {reportsByPartnerData} from "../../../store/reports/reportsByPartnerSlice/reportsByPartnerSlice";
import {reportsByOrderData, reportsByOrderDataExcel} from "../../../store/reports/reportOrdersSlice/reportOrdersSlice";
import OrderItem from "./order-item/order-item";

function Order() {
    const dispatch = useDispatch();
    const { reportsByPartner } = useSelector((state) => state.reportsByPartner);
    const forFilter  = useSelector((state) => state.reportsByOrder.reportsByOrder.for_filter);
    const [totalCount , setTotalCount] = useState(0);
    const [columnNames , seColumnNames] = useState({});
    const [reportItems , setReportItems] = useState([]);
    const [totalPrice , setTotalPrice] = useState(0);
    const [linkToExcel , setLinkToExcel] = useState('');

    const showReportByOrder = (data) =>{
        dispatch(reportsByOrderData({data})).then((response)=>{
            setTotalCount(response.payload.total)
            seColumnNames(response.payload.columnHeaders)
            setReportItems(response.payload.orderServices)
            setTotalPrice(response.payload.lastRow.price)
        })
    }
    useEffect(() => {
        dispatch(reportsByOrderDataExcel()).then((response)=>{
            setLinkToExcel(response.payload.url)
        })
    }, [dispatch]);

    const getFilteredExcel = (data)=>{
        dispatch(reportsByOrderDataExcel(data)).then((response)=>{
            setLinkToExcel(response.payload.url)
        })
    }

    useEffect(() => {
        const data = {
            partner_id: 0,
            parking_id: 0,
            completed: 0,
        }
        dispatch(reportsByOrderData({data}))
    }, [dispatch]);
    return (
        <>
          <ReportHeader
             reportsByPartner={reportsByPartner}
             showReportByOrder={showReportByOrder}
             total={totalCount}
             linkToExcel={linkToExcel}
             getFilteredExcel={getFilteredExcel}
             forFilter={forFilter}
          />
            <div className="container">
                <div className="inner-page">
                    <table className="table fs-13 sortable horizontal-scrollable vertical-scrollable">
                        <thead>
                            <tr>
                                <th>{columnNames.numeration}</th>
                                <th style={{minWidth:'135px'}}>{columnNames.partner}</th>
                                <th>{columnNames.parking}</th>
                                <th>{columnNames.car_mark}</th>
                                <th>{columnNames.car_model}</th>
                                <th>{columnNames.vin}</th>
                                <th>{columnNames.license_plate}</th>
                                <th>{columnNames.order_number}</th>
                                <th>{columnNames.order_date}</th>
                                <th>{columnNames.status}</th>
                                <th>{columnNames.service}</th>
                                <th>{columnNames.completed_at}</th>
                                <th>{columnNames.price}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {reportItems.map((item, index) => {
                                return (
                                    <OrderItem
                                        item={item}
                                        index={index}
                                        key={index}
                                    />
                                );
                            })}
                            <tr className={'car-show-modal'}>
                                <td>ИТОГО</td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td><b>{totalPrice}</b></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

        </>
    );
}

export default Order;