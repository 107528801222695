import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import ApplicatonCrtUptHeader from "./applications-crt-upt-header/applications-crt-upt-header";
import ApplicationCrtUptStep1 from "./applications-crt-upt-step-1/applications-crt-upt-step-1";
import ApplicationCrtUptStep2 from "./applications-crt-upt-step-2/applications-crt-upt-step-2";

import { Nav, Tab } from "react-bootstrap";
import {
  applicationCarTypes,
  applicationsCreate
} from "../../../store/applications/applications-create/application-create-get/applicationCreateSlice";
import {
  appliationResultPost,
  appReset,
} from "../../../store/applications/applications-create/application-create-post/applicationCreatePostSlice";
import { toast } from "react-toastify";
import swal from "sweetalert";
import { selectColor } from "../../../utils/select-array";
import { setFormDataReset } from "../../../store/applications/applications-create/application-create-collect-data/application-create-collect-data";
import Preloader from "../../preloader/preloader";

function ApplicationCrtUpt() {
  const { applications } = useSelector((state) => state.applicationCreate);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [disabledBtn, setDisabledBtn] = useState(false);
  const [dataLoaded, setDataLoaded] = useState(false);
  const { user } = useSelector((state) => state.auth);

  const { serial_number } = useSelector(
    (state) => state.applicationVinDublicate
  );
  const [selectedOption, setSelectedOption] = useState({
    value: 0,
    label: "Выберите стоянку",
    name: "parking_id",
  });
  const [selectedPartner, setSelectedPartner] = useState({
    value: 0,
    label: "Выберите партнера",
    name: "partner_id",
  });

  // Получаем данные при создание машины
  useEffect(() => {
    const fetchData = async () => {
      const res = await dispatch(applicationsCreate());
      if (res.payload.token) {
        dispatch(setFormDataReset());
      }
      setDataLoaded(true); // Установка флага загрузки данных
    };

    fetchData();
  }, [dispatch]);

  //
  if (
    (applications &&
      applications.length !== 0 &&
      localStorage.getItem("attachment_token") === null) ||
      localStorage.getItem("attachment_token") === "undefined"
  ) {
    localStorage.setItem(
      "attachment_token",
      JSON.stringify(applications.token)
    );
  }
  const token = localStorage.getItem("attachment_token");
  const [currentTab, setCurrentTab] = React.useState(0);


  // Стили для select-react
  const styles = {
    option: (provided, state) => ({
      ...provided,
      fontWeight: state.isSelected ? "bold" : "normal",
      color: "black",
      backgroundColor: state.data.color,
      fontSize: state.selectProps.myFontSize,
    }),
    singleValue: (provided, state) => ({
      ...provided,
      color: "black",
      width: "160px",
      fontSize: state.selectProps.myFontSize,
    }),
    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
  };
  //

  // Валидация для первого таба
  const tabFucnt = (tab) => {

    if (user.role !== 'Partner'){
      if (
          formData.partner_id !== "" &&
          formData.partner_id &&
          formData.parking_id !== "" &&
          formData.parking_id &&
          formData.external_id !== "" &&
          formData.external_id &&
          !serial_number.error
      ) {

        setCurrentTab(tab);

      }
    }else{
      if (
          formData.parking_id !== "" &&
          formData.parking_id &&
          formData.external_id !== "" &&
          formData.external_id &&
          !serial_number.error
      ) {
        setCurrentTab(tab);
      }
    }
    getCarTypes()
  };
  //

  const getCarTypes = () => {
    let data = {
      parking_id : selectedOption.value,
      partner_id : selectedPartner.value,
      partnerUser: false
    }
    if (user.role === 'Partner') {
      data = {
        parking_id: selectedOption.value,
        partner_id: user.id,
        partnerUser: true
      }
    }
    dispatch(applicationCarTypes(data))
  }

  const { isError, isSuccess, message } = useSelector(
    (state) => state.applicationResultPost
  );

  useEffect(() => {
    if (isError) {
      toast.error(message.error);
    }

    if (isSuccess) {
      navigate("/applications");
      dispatch(appReset());
    }
  }, [isError, isSuccess, message, navigate, dispatch]);

  const formData = useSelector((state) => state.applicationCreateData);

  // Создание машины
  const onCreateApplicaiton = async (e) => {
    e.preventDefault();
    setDisabledBtn(true);
    dispatch(appliationResultPost(formData)).then((response) => {
      if (response.payload.status === 201) {
        swal("Создано!", {
          icon: "success",
        }).then(() => {
          setDisabledBtn(false);
          localStorage.removeItem("attachment_token");
          window.location.replace("/applications");
        });
      } else {
        let errorMessage = "Ошибка при создании!";
        setDisabledBtn(false);

        if (response.payload.errors) {
          const errorMessages = Object.values(response.payload.errors).reduce(
            (acc, val) => acc.concat(val),
            []
          );
          errorMessage = errorMessages.join("\n");
        }
        swal(errorMessage, {
          icon: "error",
        });
      }
    });
  };
  //

  return (
    <>
      {dataLoaded ? (
        <form
          method="POST"
          onSubmit={onCreateApplicaiton}
          encType="multipart/form-data"
        >
          <ApplicatonCrtUptHeader
            tab={currentTab}
            tabfunc={tabFucnt}
            title={applications.title}
            status={applications.statuses}
            token={token}
            styles={styles}
            disabledBtn={disabledBtn}
          />
          <div className="container">
            <div className="inner-page">
              <div className="row no-gutters position-relative">
                <div className="tab-content tab-content-main col-md-12">
                  <Tab.Container id="left-tabs-example" activeKey={currentTab}>
                    <Nav variant="pills" className="d-none">
                      <Nav.Item>
                        <Nav.Link eventKey={0} disabled={currentTab !== 0}>
                          1
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey={1} disabled={currentTab !== 1}>
                          2
                        </Nav.Link>
                      </Nav.Item>
                    </Nav>
                    <Tab.Content>
                      <Tab.Pane eventKey={0} disabled={currentTab !== 0}>
                        <ApplicationCrtUptStep1
                          partners={applications.partners}
                          parkings={applications.parkings}
                          setSelectedOption={setSelectedOption}
                          selectedOption={selectedOption}
                          setSelectedPartner={setSelectedPartner}
                        />
                      </Tab.Pane>
                      <Tab.Pane eventKey={1} disabled={currentTab !== 1}>
                        <ApplicationCrtUptStep2
                          colors={selectColor(applications.colors)}
                          tab={currentTab}
                          selectedOption={selectedOption}
                          selectedPartner={selectedPartner}
                          application={applications}
                        />
                      </Tab.Pane>
                    </Tab.Content>
                  </Tab.Container>
                </div>
              </div>
            </div>
          </div>
        </form>
      ) : (
        <Preloader />
      )}
    </>
  );
}

export default ApplicationCrtUpt;
