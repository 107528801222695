import React, { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import { setApplicationDataSlice } from "../../../../store/applications/applications-create/application-create-collect-data/application-create-collect-data";

import {
  applicationsBrand,
  brandReset,
} from "../../../../store/applications/applications-select/application-brand/applicationBrandSlice";
import {
  applicationsGenerations,
  generationReset,
} from "../../../../store/applications/applications-select/application-generations/applicationGenerationsSlice";
import {
  applicationsModel,
  modelReset,
} from "../../../../store/applications/applications-select/application-model/applicationModelSlice";
import {
  applicationsSeries,
  seriesReset,
} from "../../../../store/applications/applications-select/application-series/applicationSeriesSlice";
import { applicationsType } from "../../../../store/applications/applications-select/application-type/applicationTypeSlice";
import {
  applicationsYear,
  yearReset,
} from "../../../../store/applications/applications-select/application-year/applicationYearSlice";
import {
  applicationsEngines,
  enginesReset,
} from "../../../../store/applications/applications-select/applications-engines/applicationEnginesSlice";
import {
  applicationsGears,
  gearsReset,
} from "../../../../store/applications/applications-select/applications-gears/applicationGearsSlice";
import {
  applicationsModifications,
  modificationsReset,
} from "../../../../store/applications/applications-select/applications-modification/applicationModificationsSlice";
import {
  applicationsTransmissions,
  transmissionsReset,
} from "../../../../store/applications/applications-select/applications-transmission/applicationTransmissionsSlice";
import { pts } from "../../../../utils/select-array";
import selectStyle from "../../../../utils/select-styles";
import FileUploadForm from "../../../file-upload/file-upload";
import ImageUploader from "../../../image-upload/image-upload";
import {
  applicationCarTypes,
  applicationsCreate
} from "../../../../store/applications/applications-create/application-create-get/applicationCreateSlice";

function ApplicationCrtUptStep2({ colors, tab, selectedOption,selectedPartner }) {
  const dispatch = useDispatch();

  const { appType } = useSelector((state) => state.applicationType);
  const {carTypes} = useSelector((state) => state.applicationCreate.applications);
  const carTypesList = useSelector((state) => state.applicationCreate.carTypes.carTypes);
  const appl = useSelector((state) => state.applicationCreate.applications);
  const { brand } = useSelector((state) => state.applicationBrand);
  const { model } = useSelector((state) => state.applicationModel);
  const { year } = useSelector((state) => state.applicationYear);
  const { generations } = useSelector((state) => state.applicationGenerations);
  const { series } = useSelector((state) => state.applicationSeries);
  const { modifications } = useSelector(
    (state) => state.applicationModifications
  );
  // Для получение роли
  const { user } = useSelector((state) => state.auth);
  //
  const { engines } = useSelector((state) => state.applicationEngines);
  const { transmissions } = useSelector((state) => state.applicationTransmissions);
  const { gears } = useSelector((state) => state.applicationGears);
  const [type, setType] = useState(0);
  const [brandActive, setBrand] = useState(0);
  const [modelActive, setModel] = useState(0);
  const [yearActive, setYear] = useState(0);
  const [generationsActive, setGenerations] = useState(0);
  const [seriesActive, setSeries] = useState(0);
  const [modificationActive, setModification] = useState(0);
  const [enginesActive, setEngines] = useState(0);
  const [transmissionsActive, setTransmissions] = useState(0);
  const [gearsActive, setGears] = useState(0);
  const [subType, setSubType] = useState(0);
  const [toggleTrailerPrices, setToggleTrailerPrices] = useState(false)
  const applicationTypeClick = (func) => {
    dispatch(func);
  };

  const [undefinedFieldName, setUndefinedFieldName]= useState([])

  const setUndefinedFieldHandler = (e) => {
    const {name} = e.target
    setUndefinedFieldName((prevState) => {
      if (prevState.includes(name)){
        return prevState.filter((item) => item!== name)
      }else {
        return [...prevState, name]
      }
    })
  }
  // console.log(undefinedFieldName)

  // Проверяем поколение и если оно пусто то выдаем кузов

  useEffect(() => {
    // Если нету Поколение
    if (generations === 0) {
      dispatch(
        applicationsSeries({
          generetionId: generationsActive,
          modelsId: modelActive,
        })
      );
    }
    //
  }, [generationsActive]);
  //
  useEffect(() => {
    let data = {
      parking_id : selectedOption.value,
      partner_id : selectedPartner.value,
      partnerUser: false
    }
    if (user.role === 'Partner'){
      data = {
        parking_id : selectedOption.value,
        partner_id : user.id,
        partnerUser: true
      }
    }
    dispatch(applicationCarTypes(data))
  }, []);

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target ? e.target : e;

    //у прицепов id = 4
    if (name === 'car_type_id' ){
      setSubType(carTypesList.filter(carType => carType.id === value))
      setToggleTrailerPrices(true)

      let data = {
        parking_id : selectedOption.value,
        partner_id : selectedPartner.value,
        partnerUser: false
      }
      if (user.role === 'Partner'){
        data = {
          parking_id : selectedOption.value,
          partner_id : user.id,
          partnerUser: true
        }
      }
      dispatch(applicationCarTypes(data))
    }

    let newValue = value;
    if (type === "checkbox") {
      newValue = checked ? 1 : 0;
    }
    dispatch(
      setApplicationDataSlice({
        [name]: isNaN(newValue) ? newValue : parseInt(newValue),
      })
    );

    if (name === "car_type_id" && value !== brandActive) {
      setBrand(0);
      setModel(0);
      setYear(0);
      setGenerations(0);
      setSeries(0);
      setModification(0);
      setEngines(0);
      setTransmissions(0);
      setGears(0);

      dispatch(brandReset());
      dispatch(modelReset());
      dispatch(yearReset());
      dispatch(generationReset());
      dispatch(seriesReset());
      dispatch(modificationsReset());
      dispatch(enginesReset());
      dispatch(transmissionsReset());
      dispatch(gearsReset());

      dispatch(
        setApplicationDataSlice({
          [name]: isNaN(newValue) ? newValue : parseInt(newValue),
          car_mark_id: null,
          car_model_id: null,
          year: null,
          car_generation_id: null,
          car_series_id: null,
          car_modification_id: null,
          car_engine_id: null,
          car_transmission_id: null,
          car_gear_id: null,
        })
      );
    }

    if (name === "car_mark_id" && value !== brandActive) {
      setModel(0);
      setYear(0);
      setGenerations(0);
      setSeries(0);
      setModification(0);
      setEngines(0);
      setTransmissions(0);
      setGears(0);

      dispatch(yearReset());
      dispatch(generationReset());
      dispatch(seriesReset());
      dispatch(modificationsReset());
      dispatch(enginesReset());
      dispatch(transmissionsReset());
      dispatch(gearsReset());
      dispatch(
        setApplicationDataSlice({
          [name]: isNaN(newValue) ? newValue : parseInt(newValue),
          car_model_id: null,
          year: null,
          car_generation_id: null,
          car_series_id: null,
          car_modification_id: null,
          car_engine_id: null,
          car_transmission_id: null,
          car_gear_id: null,
        })
      );
    }

    if (name === "car_model_id" && value !== modelActive) {
      setYear(0);
      setGenerations(0);
      setSeries(0);
      setModification(0);
      setEngines(0);
      setTransmissions(0);
      setGears(0);

      dispatch(generationReset());
      dispatch(seriesReset());
      dispatch(modificationsReset());
      dispatch(enginesReset());
      dispatch(transmissionsReset());
      dispatch(gearsReset());

      dispatch(
        setApplicationDataSlice({
          [name]: isNaN(newValue) ? newValue : parseInt(newValue),
          year: null,
          car_generation_id: null,
          car_series_id: null,
          car_modification_id: null,
          car_engine_id: null,
          car_transmission_id: null,
          car_gear_id: null,
        })
      );
    }

    if (name === "year" && value !== yearActive) {
      setGenerations(0);
      setSeries(0);
      setModification(0);
      setEngines(0);
      setTransmissions(0);
      setGears(0);

      dispatch(seriesReset());
      dispatch(modificationsReset());
      dispatch(enginesReset());
      dispatch(transmissionsReset());
      dispatch(gearsReset());

      dispatch(
        setApplicationDataSlice({
          [name]: isNaN(newValue) ? newValue : parseInt(newValue),
          car_generation_id: null,
          car_series_id: null,
          car_modification_id: null,
          car_engine_id: null,
          car_transmission_id: null,
          car_gear_id: null,
        })
      );
    }

    if (name === "car_generation_id" && value !== generationsActive) {
      setSeries(0);
      setModification(0);
      setEngines(0);
      setTransmissions(0);
      setGears(0);

      dispatch(modificationsReset());
      dispatch(enginesReset());
      dispatch(transmissionsReset());
      dispatch(gearsReset());

      dispatch(
        setApplicationDataSlice({
          [name]: isNaN(newValue) ? newValue : parseInt(newValue),
          car_series_id: null,
          car_modification_id: null,
          car_engine_id: null,
          car_transmission_id: null,
          car_gear_id: null,
        })
      );
    }

    if (name === "car_series_id" && value !== seriesActive) {
      setModification(0);
      setEngines(0);
      setTransmissions(0);
      setGears(0);

      dispatch(enginesReset());
      dispatch(transmissionsReset());
      dispatch(gearsReset());

      dispatch(
        setApplicationDataSlice({
          [name]: isNaN(newValue) ? newValue : parseInt(newValue),
          car_modification_id: null,
          car_engine_id: null,
          car_transmission_id: null,
          car_gear_id: null,
        })
      );
    }

    if (name === "car_modification_id" && value !== modificationActive) {
      setEngines(0);
      setTransmissions(0);
      setGears(0);

      dispatch(transmissionsReset());
      dispatch(gearsReset());

      dispatch(
        setApplicationDataSlice({
          [name]: isNaN(newValue) ? newValue : parseInt(newValue),
          car_engine_id: null,
          car_transmission_id: null,
          car_gear_id: null,
        })
      );
    }

    if (name === "car_engine_id" && value !== enginesActive) {
      setTransmissions(0);
      setGears(0);

      dispatch(gearsReset());

      dispatch(
        setApplicationDataSlice({
          [name]: isNaN(newValue) ? newValue : parseInt(newValue),
          car_transmission_id: null,
          car_gear_id: null,
        })
      );
    }

    if (name === "car_transmission_id" && value !== transmissionsActive) {
      setGears(0);

      dispatch(
        setApplicationDataSlice({
          [name]: isNaN(newValue) ? newValue : parseInt(newValue),
          car_gear_id: null,
        })
      );
    }
  };

  // Делаем проверку на количество и если одно то запускаем код

  // Модель
  useEffect(() => {
    // Если есть одно поколение
    if (tab === 1) {
      if (model) {
        if (model.length === 1) {
          const item = model[0]; // Получаем первый и единственный элемент массива model
          const modelsId = item.id;
          setModel(item.id);
          dispatch(applicationsYear(modelsId));
          dispatch(
            setApplicationDataSlice({
              car_model_id: isNaN(modelsId) ? modelsId : parseInt(modelsId),
            })
          );
        }
      }
    }
  }, [model]);
  //

  // Год
  useEffect(() => {
    // Если есть одно поколение
    if (tab === 1) {
      if (year) {
        if (year.length === 1) {
          const item = year[0]; // Получаем первый и единственный элемент массива year
          const modelId = modelActive;
          const yearId = item.id;
          dispatch(applicationsGenerations({ modelId, yearId }));
          dispatch(
            setApplicationDataSlice({
              year: isNaN(yearId) ? yearId : parseInt(yearId),
            })
          );
        }
      }
    }
  }, [year]);
  //

  // ПОКОЛЕНИЕ
  useEffect(() => {
    // Если есть одно поколение
    if (tab === 1) {
      if (generations) {
        if (generations.length === 1) {
          const item = generations[0]; // Получаем первый и единственный элемент массива generations
          const generetionId = item.id;
          const modelsId = modelActive;
          dispatch(applicationsSeries({ generetionId, modelsId }));
          dispatch(
            setApplicationDataSlice({
              car_generation_id: isNaN(generetionId)
                ? generetionId
                : parseInt(generetionId),
            })
          );
        }
      }
    }
  }, [generations]);
  //

  // КУЗОВ
  useEffect(() => {
    // Если есть одно поколение
    if (tab === 1) {
      if (series) {
        if (series.length === 1) {
          const item = series[0]; // Получаем первый и единственный элемент массива generations
          const modelId = modelActive;
          const seriesId = item.id;
          const yearId = yearActive;
          dispatch(applicationsModifications({ modelId, seriesId, yearId }));
          dispatch(
            setApplicationDataSlice({
              car_series_id: isNaN(seriesId) ? seriesId : parseInt(seriesId),
            })
          );
        }
      }
    }
  }, [series]);
  //

  // МОДИФИКАЦИЯ
  useEffect(() => {
    // Если есть одно поколение
    if (tab === 1) {
      if (modifications) {
        if (modifications.length === 1) {
          const item = modifications[0]; // Получаем первый и единственный элемент массива generations
          setModification(item.id);

          dispatch(applicationsEngines(item.id));
          dispatch(
            setApplicationDataSlice({
              car_modification_id: isNaN(item.id) ? item.id : parseInt(item.id),
            })
          );
        }
      }
    }
  }, [modifications]);
  //

  // ДВИГАТЕЛЬ
  useEffect(() => {
    // Если есть одно поколение
    if (tab === 1) {
      if (engines) {
        if (engines.length === 1) {
          const item = engines[0];
          const enginesID = item.id;
          dispatch(applicationsTransmissions(modificationActive));
          dispatch(
            setApplicationDataSlice({
              car_engine_id: isNaN(enginesID) ? enginesID : parseInt(enginesID),
            })
          );
        }
      }
    }
  }, [engines, modificationActive]);
  //

  // КПП
  useEffect(() => {
    // Если есть одно поколение
    if (tab === 1) {
      if (transmissions) {
        if (transmissions.length === 1) {
          const item = transmissions[0];
          const transmissionsId = item.id;
          dispatch(applicationsGears(modificationActive));
          dispatch(
            setApplicationDataSlice({
              car_transmission_id: isNaN(transmissionsId)
                ? transmissionsId
                : parseInt(transmissionsId),
            })
          );
        }
      }
    }
  }, [transmissions]);
  //

  // Привод
  useEffect(() => {
    // Если есть одно поколение
    if (tab === 1) {
      if (gears) {
        if (gears.length === 1) {
          const item = gears[0];
          const gearsId = item.id;
          dispatch(
            setApplicationDataSlice({
              car_gear_id: isNaN(gearsId) ? gearsId : parseInt(gearsId),
            })
          );
        }
      }
    }
  }, [gears]);
  //

  // Тех.состояние
  const [checkboxStates, setCheckboxStates] = useState({
    condition_engine: [],
    condition_transmission: [],
    condition_electric: [],
    condition_gear: [],
  });

  const handleCheckboxChange = (name, value) => {
    setCheckboxStates((prevState) => {
      if (Array.isArray(prevState[name])) {
        // Если значение уже является массивом
        if (prevState[name].includes(value)) {
          // Удаляем значение из массива
          return {
            ...prevState,
            [name]: prevState[name].filter((item) => item !== value),
          };
        } else {
          // Добавляем значение в массив
          return {
            ...prevState,
            [name]: [...prevState[name], value],
          };
        }
      } else {
        // Если значение не является массивом
        return {
          ...prevState,
          [name]: [value],
        };
      }
    });
  };

  useEffect(() => {
    // Если есть одно поколение
    dispatch(setApplicationDataSlice(checkboxStates));
  }, [checkboxStates]);
  //


  // Очищаем Марка и модель поколение и тд. перед началом работы
  useEffect(() => {
    dispatch(brandReset());
    dispatch(modelReset());
    dispatch(yearReset());
    dispatch(generationReset());
    dispatch(seriesReset());
    dispatch(modificationsReset());
    dispatch(enginesReset());
    dispatch(transmissionsReset());
    dispatch(gearsReset());
  }, [tab]);
  //
  const [searchType, setSearchType] = useState("");
  const [searchMark, setSearchMark] = useState("");
  const [searchModel, setSearchModel] = useState("");
  const [searchYear, setSearchYear] = useState("");
  const filterByType = (e) => {
    const { value } = e.target;
    setSearchType(value);
  };

  const filterByMark = (e) => {
    const { value } = e.target;
    setSearchMark(value);
  };

  const filterByModel = (e) => {
    const { value } = e.target;
    setSearchModel(value);
  };

  const filterByYear = (e) => {
    const { value } = e.target;
    setSearchYear(value);
  };
  return (
    <div className="row no-gutters " id="v-pills-2">
      <div className={'prise_parking'} >
        <div>
          Стоянка: {selectedOption.label}
        </div>
          {toggleTrailerPrices &&
        <div className={'prise_parking__right'}>
          <span>Стоимость:</span>
          <div className="d-flex radio-check-list">
            {subType[0].car_subtypes ? subType[0].car_subtypes.map((subTypeItem, index) => {
              return <div className="mt-2 mb-3">
                <label className="switch-radio-wrap custom_radio">
                  <input
                      type="radio"
                      name="car_subtype_id"
                      defaultValue={parseInt(subTypeItem.id)}
                      defaultChecked={index === 2 ? true : false}
                      onChange={handleInputChange}
                  />
                  <span className="switcher-radio"></span>
                  <span>{subTypeItem.name}: {subTypeItem.price}руб.</span>
                </label>
              </div>
            }) :
                <div className="mt-2 mb-3">
                <label className="switch-radio-wrap custom_radio">

                  <span> {subType[0].price}руб.</span>
                </label>
              </div>
            }
          </div>
        </div>
        }

      </div>
      <div className="col-md-12">
        {/* Марка и модель */}
        <div className="inner-page__item">
          <div className="inner-item-title">Марка и модель</div>
          <div className="row mr-offset-20">
            <div className="col-12">
              <div className="tabform__cartlist w-100 d-flex">
                {/* Тип Авто */}
                <fieldset className="tabform__cart select first-cart car_type_id fieldset new-style-model">
                  <legend className="legend">
                    Тип авто...
                    <span className="mob-arrow"></span>
                  </legend>
                  <div className="tabform__mob-dd type-card">
                    <input
                      type="text"
                      placeholder="Поиск"
                      className="select-search"
                      onChange={(e) => filterByType(e)}
                    />
                    <ul className="select-list tabform__ul type-list">
                      {carTypesList &&
                      carTypesList
                          .filter((item) =>
                            item.name
                              .toLowerCase()
                              .includes(searchType.toLowerCase())
                          )
                          .map((item, index) => {
                            return (
                              <li
                                className={`select-item tabform__li ${
                                  type === item.id ? "active" : ""
                                }`}
                                key={index}
                                onClick={() => {
                                  setType(item.id);
                                  applicationTypeClick(
                                    applicationsBrand(item.id)
                                  );
                                  handleInputChange({
                                    name: "car_type_id",
                                    value: item.id,
                                  });
                                }}
                              >
                                <div>{item.name}</div>
                              </li>
                            );
                          })}
                    </ul>
                  </div>
                </fieldset>
                {/*  */}

                {/* Если ПРОЧЕЕ то убираем все поля для выборки и оставляем поля для ввода имени авто */}
                {type === 27 ? (
                  <div className="col">
                    <label htmlFor="reg_number">Заголовок для авто</label>
                    <textarea
                      className="form-control mw-100"
                      rows="4"
                      name="car_title"
                      onChange={handleInputChange}
                    ></textarea>
                  </div>
                ) : (
                  <>
                    {/* Марка авто */}
                    <div className="car__mark-with-undefined new-style-model">
                      {/*<input type="text" name={'car_mark'} placeholder={''}/>*/}
                      <fieldset className="tabform__cart select car_mark_id fieldset ">
                        <legend className="legend">
                          Марка авто <span className="mob-arrow"></span>
                        </legend>
                        <div className="tabform__mob-dd type-card">
                          <input
                              type="text"
                              placeholder="Поиск"
                              className="select-search"
                              onChange={(e) => filterByMark(e)}
                          />
                          <ul className="tabform__ul select-list type-list">
                            {brand && brand.length !== 0 ? (
                                brand
                                    .filter((item) =>
                                        item.name
                                            .toLowerCase()
                                            .includes(searchMark.toLowerCase())
                                    )
                                    .map((item, index) => {
                                      return (
                                          <li
                                              className={`select-item tabform__li ${
                                                  brandActive === item.id ? "active" : ""
                                              }`}
                                              key={index}
                                              onClick={() => {
                                                setBrand(item.id);
                                                applicationTypeClick(
                                                    applicationsModel(item.id)
                                                );
                                                handleInputChange({
                                                  name: "car_mark_id",
                                                  value: item.id,
                                                });
                                              }}
                                          >
                                            <div>{item.name}</div>
                                          </li>
                                      );
                                    })
                            ) : (
                                <li className="placeholder statuspink">
                                  Выберите марку авто
                                </li>
                            )}
                          </ul>
                        </div>
                      </fieldset>
                      {/*<label className="switch-radio-wrap mt-2">*/}
                      {/*  <input*/}
                      {/*      className="checkbox-unknown cvin"*/}
                      {/*      type="checkbox"*/}
                      {/*      name="mark"*/}
                      {/*      onChange={setUndefinedFieldHandler}*/}
                      {/*  />*/}
                      {/*  <span className="switcher-radio"></span>*/}
                      {/*  <span>неизвестно</span>*/}
                      {/*</label>*/}
                    </div>

                    {/*  */}

                    {/* Модель авто */}
                    <div className="car__mark-with-undefined new-style-model">
                      <fieldset className="tabform__cart select car_model_id fieldset ">
                      <legend className="legend">
                        Модель авто <span className="mob-arrow"></span>
                      </legend>
                      <div className="tabform__mob-dd type-card">
                        <input
                          type="text"
                          placeholder="Поиск"
                          className="select-search"
                          onChange={(e) => filterByModel(e)}
                        />
                        <ul
                          className="select-list tabform__ul type-list"
                          data-placeholder="Выберите марку авто"
                        >
                          {model && model.length !== 0 ? (
                            model
                              .filter((item) =>
                                item.name
                                  .toLowerCase()
                                  .includes(searchModel.toLowerCase())
                              )
                              .map((item, index) => {
                                return (
                                  <li
                                    className={`select-item tabform__li ${
                                      modelActive === item.id ||
                                      model.length === 1
                                        ? "active"
                                        : ""
                                    }`}
                                    key={index}
                                    onClick={() => {
                                      setModel(item.id);
                                      applicationTypeClick(
                                        applicationsYear(item.id)
                                      );
                                      handleInputChange({
                                        name: "car_model_id",
                                        value: item.id,
                                      });
                                    }}
                                  >
                                    <div>{item.name}</div>
                                  </li>
                                );
                              })
                          ) : (
                            <li className="placeholder statuspink">
                              Выберите модель авто
                            </li>
                          )}
                        </ul>
                      </div>
                    </fieldset>
                      {/*<label className="switch-radio-wrap mt-2">*/}
                      {/*  <input*/}
                      {/*      className="checkbox-unknown cvin"*/}
                      {/*      type="checkbox"*/}
                      {/*      name="model"*/}
                      {/*      onChange={setUndefinedFieldHandler}*/}
                      {/*  />*/}
                      {/*  <span className="switcher-radio"></span>*/}
                      {/*  <span>неизвестно</span>*/}
                      {/*</label>*/}
                    </div>
                    {/*  */}

                    {/* Год авто */}
                    <div className="car__mark-with-undefined new-style-model">
                      <fieldset className="tabform__cart select year fieldset ">
                      <legend className="legend">
                        Год авто.<span className="mob-arrow"></span>
                      </legend>
                      <div className="tabform__mob-dd type-card">
                        <input
                          type="text"
                          placeholder="Поиск"
                          onChange={(e) => filterByYear(e)}
                          className="select-search"
                        />
                        <ul className="select-list tabform__ul type-list">
                          {year && year.length !== 0 ? (
                            year
                              .filter((item) =>
                                item.name
                                  .toString()
                                  .toLowerCase()
                                  .includes(searchYear.toLowerCase())
                              )
                              .map((item, index) => {
                                return (
                                  <li
                                    className={`select-item tabform__li ${
                                      yearActive === item.id ||
                                      year.length === 1
                                        ? "active"
                                        : ""
                                    }`}
                                    key={index}
                                    onClick={() => {
                                      setYear(item.id);
                                      applicationTypeClick(
                                        applicationsGenerations({
                                          modelId: modelActive,
                                          yearId: item.id,
                                        })
                                      );
                                      handleInputChange({
                                        name: "year",
                                        value: item.id,
                                      });
                                    }}
                                  >
                                    <div>{item.name}</div>
                                  </li>
                                );
                              })
                          ) : (
                            <li className="placeholder statuspink">
                              Выберите год авто
                            </li>
                          )}
                        </ul>
                      </div>
                    </fieldset>
                      {/*<label className="switch-radio-wrap mt-2">*/}
                      {/*  <input*/}
                      {/*      className="checkbox-unknown cvin"*/}
                      {/*      type="checkbox"*/}
                      {/*      name="year"*/}
                      {/*      onChange={setUndefinedFieldHandler}*/}

                      {/*  />*/}
                      {/*  <span className="switcher-radio"></span>*/}
                      {/*  <span>неизвестно</span>*/}
                      {/*</label>*/}
                    </div>
                    {/*  */}
                  </>
                )}
                {/*  */}
              </div>
            </div>
          </div>
        </div>
        {/*  */}
        {type === 27 ? (
          ""
        ) : (
          <>
            {/* Поколение и модификация */}
            <div className="inner-page__item">
              <div className="inner-item-title">Поколение и модификация</div>
              <div className="row">
                <div className="col-12">
                  <div className="tabform__cartlist tabform__cartlist-col-3 d-flex justify-content-between">
                    {/* Поколение  */}
                    <fieldset className="tabform__cart select cart-3 fieldset">
                      <legend className="legend">
                        Поколение.. <span className="mob-arrow"></span>
                      </legend>
                      <div className="tabform__mob-dd">
                        <input
                          type="text"
                          placeholder="Поиск"
                          className="select-search"
                        />
                        <ul className="select-list tabform__ul type-list">
                          {generations &&
                          generations.length !== 0 &&
                          generations !== 0 ? (
                            generations.map((item, index) => {
                              return (
                                <li
                                  className={`select-item tabform__li ${
                                    generationsActive === item.id ||
                                    generations.length === 1
                                      ? "active"
                                      : ""
                                  }`}
                                  key={index}
                                  onClick={() => {
                                    setGenerations(item.id);
                                    applicationTypeClick(
                                      applicationsSeries({
                                        generetionId: item.id,
                                        modelsId: modelActive,
                                      })
                                    );
                                    handleInputChange({
                                      name: "car_generation_id",
                                      value: item.id,
                                    });
                                  }}
                                >
                                  <div>{item.name}</div>
                                </li>
                              );
                            })
                          ) : (
                            <li className="placeholder statuspink">
                              Выберите поколение авто
                            </li>
                          )}
                        </ul>
                      </div>
                    </fieldset>
                    {/*  */}

                    {/* Кузов  */}
                    <fieldset className="tabform__cart select cart-3 fieldset">
                      <legend className="legend">
                        Кузов...<span className="mob-arrow"></span>
                      </legend>
                      <div className="tabform__mob-dd">
                        <input
                          type="text"
                          placeholder="Поиск"
                          className="select-search"
                        />
                        <ul className="select-list tabform__ul type-list">
                          {series && series.length !== 0 ? (
                            series.map((item, index) => {
                              return (
                                <li
                                  className={`select-item tabform__li ${
                                    seriesActive === item.id ||
                                    series.length === 1
                                      ? "active"
                                      : ""
                                  }`}
                                  key={index}
                                  onClick={() => {
                                    setSeries(item.id);
                                    applicationTypeClick(
                                      applicationsModifications({
                                        modelId: modelActive,
                                        seriesId: item.id,
                                        yearId: yearActive,
                                      })
                                    );
                                    handleInputChange({
                                      name: "car_series_id",
                                      value: item.id,
                                    });
                                  }}
                                >
                                  <div>{item.name}</div>
                                </li>
                              );
                            })
                          ) : (
                            <li className="placeholder statuspink">
                              Выберите кузов авто
                            </li>
                          )}
                        </ul>
                      </div>
                    </fieldset>
                    {/*  */}

                    {/* Модификация */}
                    <fieldset
                      className="tabform__cart select cart-3 fieldset"
                      id="modifications"
                    >
                      <legend className="legend">
                        Модификация... <span className="mob-arrow"></span>
                      </legend>
                      <div className="tabform__mob-dd">
                        <input
                          type="text"
                          placeholder="Поиск"
                          className="select-search"
                        />
                        <ul className="select-list tabform__ul type-list">
                          {modifications && modifications.length !== 0 ? (
                            modifications.map((item, index) => {
                              return (
                                <li
                                  className={`select-item tabform__li ${
                                    modificationActive === item.id ||
                                    modifications.length === 1
                                      ? "active"
                                      : ""
                                  }`}
                                  key={index}
                                  onClick={() => {
                                    setModification(item.id);
                                    applicationTypeClick(
                                      applicationsEngines(item.id)
                                    );
                                    handleInputChange({
                                      name: "car_modification_id",
                                      value: item.id,
                                    });
                                  }}
                                >
                                  <div>{item.name}</div>
                                </li>
                              );
                            })
                          ) : (
                            <li className="placeholder statuspink">
                              Выберите модификацию авто
                            </li>
                          )}
                        </ul>
                      </div>
                    </fieldset>
                    {/*  */}

                    {/* Двигатель */}
                    <fieldset
                      className="tabform__cart select cart-3 fieldset"
                      id="engines"
                    >
                      <legend className="legend">
                        Двигатель... <span className="mob-arrow"></span>
                      </legend>
                      <div className="tabform__mob-dd">
                        <input
                          type="text"
                          placeholder="Поиск"
                          className="select-search"
                        />

                        <ul
                          className="select-list tabform__ul type-list"
                          data-placeholder="Выберите двигатель авто"
                        >
                          {engines && engines.length !== 0 ? (
                            engines.map((item, index) => {
                              return (
                                <li
                                  className={`select-item tabform__li ${
                                    enginesActive === item.id ||
                                    engines.length === 1
                                      ? "active"
                                      : ""
                                  }`}
                                  key={index}
                                  onClick={() => {
                                    setEngines(item.id);
                                    applicationTypeClick(
                                      applicationsTransmissions(
                                        modificationActive
                                      )
                                    );
                                    handleInputChange({
                                      name: "car_engine_id",
                                      value: item.id,
                                    });
                                  }}
                                >
                                  <div>{item.name}</div>
                                </li>
                              );
                            })
                          ) : (
                            <li className="placeholder statuspink">
                              Выберите двигатель авто
                            </li>
                          )}
                        </ul>
                      </div>
                    </fieldset>
                    {/*  */}

                    {/* КПП */}
                    <fieldset
                      className="tabform__cart select cart-3 fieldset"
                      id="transmissions"
                    >
                      <legend className="legend">
                        КПП... <span className="mob-arrow"></span>
                      </legend>
                      <div className="tabform__mob-dd">
                        <input
                          type="text"
                          placeholder="Поиск"
                          className="select-search"
                        />

                        <ul
                          className="select-list tabform__ul type-list"
                          data-placeholder="Выберите двигатель авто"
                        >
                          {transmissions && transmissions.length !== 0 ? (
                            transmissions.map((item, index) => {
                              return (
                                <li
                                  className={`select-item tabform__li ${
                                    transmissionsActive === item.id ||
                                    transmissions.length === 1
                                      ? "active"
                                      : ""
                                  }`}
                                  key={index}
                                  onClick={() => {
                                    setTransmissions(item.id);
                                    applicationTypeClick(
                                      applicationsGears(modificationActive)
                                    );
                                    handleInputChange({
                                      name: "car_transmission_id",
                                      value: item.id,
                                    });
                                  }}
                                >
                                  <div>{item.name}</div>
                                </li>
                              );
                            })
                          ) : (
                            <li className="placeholder statuspink">
                              Выберите КПП авто
                            </li>
                          )}
                        </ul>
                      </div>
                    </fieldset>
                    {/*  */}

                    {/* Привод  */}
                    <fieldset
                      className="tabform__cart select cart-3 fieldset"
                      id="gears"
                    >
                      <legend className="legend">
                        Привод... <span className="mob-arrow"></span>
                      </legend>
                      <div className="tabform__mob-dd">
                        <input
                          type="text"
                          placeholder="Поиск"
                          className="select-search"
                        />
                        <ul
                          className="select-list tabform__ul type-list"
                          data-placeholder="Выберите двигатель авто"
                        >
                          {gears && gears.length !== 0 ? (
                            gears.map((item, index) => {
                              return (
                                <li
                                  className={`select-item tabform__li ${
                                    gearsActive === item.id ||
                                    gears.length === 1
                                      ? "active"
                                      : ""
                                  }`}
                                  key={index}
                                  onClick={() => {
                                    setGears(item.id);
                                    applicationTypeClick(
                                      applicationsTransmissions(
                                        modificationActive
                                      )
                                    );
                                    handleInputChange({
                                      name: "car_gear_id",
                                      value: item.id,
                                    });
                                  }}
                                >
                                  <div>{item.name}</div>
                                </li>
                              );
                            })
                          ) : (
                            <li className="placeholder statuspink">
                              Выберите привод авто
                            </li>
                          )}
                        </ul>
                      </div>
                    </fieldset>
                    {/*  */}
                  </div>
                </div>
              </div>
            </div>
            {/*  */}
          </>
        )}

        {/* Административная информация */}
        {/* <div className="inner-page__item">
          <div className="inner-item-title">Административная информация</div>
          <div className="row">
            <div className="col-6">
              <label className="field-style">
                <span>VIN</span>
                <input className="vin" type="text" placeholder="Не указан" />
              </label>
            </div>
            <div className="col-6">
              <label className="field-style">
                <span>Гос. номер</span>
                <input
                  className="license_plate"
                  id="plate"
                  type="text"
                  placeholder="Не указан"
                />
              </label>
            </div>
          </div>
        </div> */}
        {/*  */}

        {/* Документы */}
        {user.role !== 'Partner' && (
            <div className="inner-page__item">
              <div className="inner-item-title">Документы</div>
              <div className="row">
                <div className="col-6">
                  <label className="field-style">
                    <span>СТС</span>
                    <input
                        type="text"
                        name="sts"
                        onChange={handleInputChange}
                        placeholder="Не указан"
                    />
                  </label>
                  {user.role !== "Operator" && (
                      <div className="mt-2">
                        <label className="switch-radio-wrap">
                          <input
                              type="checkbox"
                              name="sts_provided"
                              defaultValue="1"
                              onChange={handleInputChange}
                          />
                          <span className="switcher-radio"></span>
                          <span>Принят на хранение</span>
                        </label>
                      </div>
                  )}
                </div>

                <div className="col-6">
                  <div className="d-flex two-field justify-content-start">
                    <label className="field-style w-100 m-0">
                      <span>ПТС</span>
                      <input
                          onChange={handleInputChange}
                          name="pts"
                          type="text"
                          placeholder="Не указан"
                          className="w-100"
                      />
                    </label>
                    <label className="field-style select-react__style">
                      <Select
                          defaultValue={{
                            value: 0,
                            label: "Выберите тип ПТС",
                          }}
                          options={pts}
                          styles={selectStyle}
                          name="pts_type"
                          className="page-select"
                          onChange={handleInputChange}
                      />
                    </label>
                  </div>
                  {user.role !== "Operator" && (
                      <div className="mt-2">
                        <label className="switch-radio-wrap">
                          <input
                              type="checkbox"
                              name="pts_provided"
                              defaultValue="1"
                              onChange={handleInputChange}
                          />
                          <span className="switcher-radio"></span>
                          <span>Принят на хранение</span>
                        </label>
                      </div>
                  )}
                </div>
              </div>
            </div>
        )}

        {/*  */}

        {/* Информация об автомобиле */}
        <div className="inner-page__item">
          <div className="inner-item-title">Информация об автомобиле</div>
          <div className="row">
            <div className="col-6">
              <div className="inner-page__item-title">Кол-во владельцев</div>
              <div className="d-flex radio-check-list">
                <div className="mt-2 mb-3">
                  <label className="switch-radio-wrap">
                    <input
                      type="radio"
                      name="owner_number"
                      defaultValue={parseInt(1)}
                      defaultChecked={true}
                      onChange={handleInputChange}
                    />
                    <span className="switcher-radio"></span>
                    <span>Первый</span>
                  </label>
                </div>
                <div className="mt-2 mb-3">
                  <label className="switch-radio-wrap">
                    <input
                      type="radio"
                      name="owner_number"
                      defaultValue={parseInt(2)}
                      onChange={handleInputChange}
                    />
                    <span className="switcher-radio"></span>
                    <span>Второй</span>
                  </label>
                </div>
                <div className="mt-2 mb-3">
                  <label className="switch-radio-wrap">
                    <input
                      type="radio"
                      name="owner_number"
                      defaultValue={3}
                      onChange={handleInputChange}
                    />
                    <span className="switcher-radio"></span>
                    <span>Третий и более</span>
                  </label>
                </div>
              </div>
            </div>
            <div className="col-6">
              <div className="inner-page__item-title">Кол-во ключей</div>
              <div className="d-flex radio-check-list">
                <div className="mt-2 mb-3">
                  <label className="switch-radio-wrap">
                    <input
                      type="radio"
                      name="car_key_quantity"
                      defaultChecked={true}
                      defaultValue={0}
                      onChange={handleInputChange}
                    />
                    <span className="switcher-radio"></span>
                    <span>0</span>
                  </label>
                </div>
                <div className="mt-2 mb-3">
                  <label className="switch-radio-wrap">
                    <input
                      type="radio"
                      name="car_key_quantity"
                      defaultValue={1}
                      onChange={handleInputChange}
                    />
                    <span className="switcher-radio"></span>
                    <span>1</span>
                  </label>
                </div>
                <div className="mt-2 mb-3">
                  <label className="switch-radio-wrap">
                    <input
                      type="radio"
                      name="car_key_quantity"
                      defaultValue={2}
                      onChange={handleInputChange}
                    />
                    <span className="switcher-radio"></span>
                    <span>2</span>
                  </label>
                </div>
                <div className="mt-2 mb-3">
                  <label className="switch-radio-wrap">
                    <input
                      type="radio"
                      name="car_key_quantity"
                      defaultValue={3}
                      onChange={handleInputChange}
                    />
                    <span className="switcher-radio"></span>
                    <span>3</span>
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div className="row mt-5">
            <div className="col-6">
              <label className="field-style  select-react__style">
                <span>Цвет</span>
                <Select
                  defaultValue={{
                    value: 0,
                    label: "Выберите цвет",
                    name: "color",
                  }}
                  options={colors}
                  styles={selectStyle}
                  className="page-select"
                  onChange={handleInputChange}
                />
              </label>
            </div>
            <div className="col-6">
              <label className="field-style mileage">
                <span>Пробег</span>
                <input
                  type="number"
                  name="milage"
                  placeholder="Не указан"
                  onChange={handleInputChange}
                />
                <span className="mileage-type">км</span>
              </label>
            </div>
          </div>
        </div>
        {/*  */}
        {/* Тех. состояние */}
        <div className="inner-page__item">
          <div className="inner-item-title">Тех. состояние</div>
          <div className="row">
            <div className="col-12">
              <div className="row radio-check-list">
                {/* Неисправности двигателя */}
                <div className="col-3 mt-2 mb-3">
                  <label className="switch-radio-wrap bold">
                    <input
                      type="checkbox"
                      name="condition_engine"
                      defaultValue="null"
                      className="chech-dd"
                      checked={checkboxStates.condition_engine.includes("null")}
                      onChange={() => {
                        if (checkboxStates.condition_engine.includes("null")) {
                          // Если основной чекбокс был отмечен, очищаем массив и отключаем все чекбоксы
                          setCheckboxStates((prevState) => ({
                            ...prevState,
                            condition_engine: [],
                          }));
                        } else {
                          // Если основной чекбокс был снят, оставляем массив без изменений
                          setCheckboxStates((prevState) => ({
                            ...prevState,
                            condition_engine: ["null"],
                          }));
                        }
                      }}
                    />
                    <span className="switcher-radio"></span>
                    <span>Неисправности двигателя</span>
                  </label>
                  {checkboxStates.condition_engine.includes("null") && (
                    <div className="chech-dd-list active">
                      <label className="switch-radio-wrap d-flex mb-3">
                        <input
                          type="checkbox"
                          name="condition_engine"
                          defaultValue="Дымность двигателя (густой, белый, сизый, черный)"
                          onChange={() =>
                            handleCheckboxChange(
                              "condition_engine",
                              "Дымность двигателя (густой, белый, сизый, черный)"
                            )
                          }
                        />
                        <span className="switcher-radio ml-auto"></span>
                        <span className="check-box-text">
                          Дымность двигателя (густой, белый, сизый, черный)
                        </span>
                      </label>
                      <label className="switch-radio-wrap d-flex mb-3">
                        <input
                          type="checkbox"
                          name="condition_engine"
                          defaultValue="Повышенный стук и шум при работе двигателя"
                          onChange={() =>
                            handleCheckboxChange(
                              "condition_engine",
                              "Повышенный стук и шум при работе двигателя"
                            )
                          }
                        />
                        <span className="switcher-radio ml-auto"></span>
                        <span className="check-box-text">
                          Повышенный стук и шум при работе двигателя
                        </span>
                      </label>
                      <label className="switch-radio-wrap d-flex mb-3">
                        <input
                          type="checkbox"
                          name="condition_engine"
                          defaultValue="Повышенный шум при работе выхлопной системы"
                          onChange={() =>
                            handleCheckboxChange(
                              "condition_engine",
                              "Повышенный шум при работе выхлопной системы"
                            )
                          }
                        />
                        <span className="switcher-radio ml-auto"></span>
                        <span className="check-box-text">
                          Повышенный шум при работе выхлопной системы
                        </span>
                      </label>
                      <label className="switch-radio-wrap d-flex mb-3">
                        <input
                          type="checkbox"
                          name="condition_engine"
                          defaultValue="Подтекание при осмотре подкапотного пространства"
                          onChange={() =>
                            handleCheckboxChange(
                              "condition_engine",
                              "Подтекание при осмотре подкапотного пространства"
                            )
                          }
                        />
                        <span className="switcher-radio ml-auto"></span>
                        <span className="check-box-text">
                          Подтекание при осмотре подкапотного пространства
                        </span>
                      </label>
                    </div>
                  )}
                </div>
                {/*  */}

                {/* Неисправности КПП */}
                <div className="col-3 mt-2 mb-3">
                  <label className="switch-radio-wrap bold">
                    <input
                      type="checkbox"
                      name="condition_transmission"
                      className="chech-dd"
                      checked={checkboxStates.condition_transmission.includes(
                        "null"
                      )}
                      onChange={() => {
                        if (
                          checkboxStates.condition_transmission.includes("null")
                        ) {
                          // Если основной чекбокс был отмечен, очищаем массив и отключаем все чекбоксы
                          setCheckboxStates((prevState) => ({
                            ...prevState,
                            condition_transmission: [],
                          }));
                        } else {
                          // Если основной чекбокс был снят, оставляем массив без изменений
                          setCheckboxStates((prevState) => ({
                            ...prevState,
                            condition_transmission: ["null"],
                          }));
                        }
                      }}
                    />
                    <span className="switcher-radio"></span>
                    <span>Неисправности КПП</span>
                  </label>
                  {checkboxStates.condition_transmission.includes("null") && (
                    <div className="chech-dd-list active">
                      <label className="switch-radio-wrap d-flex mb-3">
                        <input
                          type="checkbox"
                          name="condition_transmission"
                          defaultValue="Рывки и толчки авто при переключении"
                          onChange={() =>
                            handleCheckboxChange(
                              "condition_transmission",
                              "Рывки и толчки авто при переключении"
                            )
                          }
                        />
                        <span className="switcher-radio ml-auto"></span>
                        <span className="check-box-text">
                          Рывки и толчки авто при переключении
                        </span>
                      </label>
                      <label className="switch-radio-wrap d-flex mb-3">
                        <input
                          type="checkbox"
                          name="condition_transmission"
                          defaultValue="Повышенный шум при переключении"
                          onChange={() =>
                            handleCheckboxChange(
                              "condition_transmission",
                              "Повышенный шум при переключении"
                            )
                          }
                        />
                        <span className="switcher-radio ml-auto"></span>
                        <span className="check-box-text">
                          Повышенный шум при переключении
                        </span>
                      </label>
                    </div>
                  )}
                </div>
                {/*  */}

                {/* Неисправности электрики */}
                <div className="col-3 mt-2 mb-3">
                  <label className="switch-radio-wrap bold">
                    <input
                      type="checkbox"
                      name="condition_electric"
                      defaultValue=""
                      className="chech-dd"
                      checked={checkboxStates.condition_electric.includes(
                        "null"
                      )}
                      onChange={() => {
                        if (
                          checkboxStates.condition_electric.includes("null")
                        ) {
                          // Если основной чекбокс был отмечен, очищаем массив и отключаем все чекбоксы
                          setCheckboxStates((prevState) => ({
                            ...prevState,
                            condition_electric: [],
                          }));
                        } else {
                          // Если основной чекбокс был снят, оставляем массив без изменений
                          setCheckboxStates((prevState) => ({
                            ...prevState,
                            condition_electric: ["null"],
                          }));
                        }
                      }}
                    />
                    <span className="switcher-radio"></span>
                    <span>Неисправности электрики</span>
                  </label>
                  {checkboxStates.condition_electric.includes("null") && (
                    <div className="chech-dd-list active">
                      <label className="switch-radio-wrap d-flex mb-3">
                        <input
                          type="checkbox"
                          name="condition_electric"
                          defaultValue="Ошибки на панели приборов при заведенном ДВС"
                          onChange={() =>
                            handleCheckboxChange(
                              "condition_electric",
                              "Ошибки на панели приборов при заведенном ДВС"
                            )
                          }
                        />
                        <span className="switcher-radio ml-auto"></span>
                        <span className="check-box-text">
                          Ошибки на панели приборов при заведенном ДВС
                        </span>
                      </label>
                      <label className="switch-radio-wrap d-flex mb-3">
                        <input
                          type="checkbox"
                          name="condition_electric"
                          defaultValue="Неправильные команды электроники"
                          onChange={() =>
                            handleCheckboxChange(
                              "condition_electric",
                              "Неправильные команды электроники"
                            )
                          }
                        />
                        <span className="switcher-radio ml-auto"></span>
                        <span className="check-box-text">
                          Неправильные команды электроники
                        </span>
                      </label>
                    </div>
                  )}
                </div>
                {/*  */}
                {/* Неисправности ходовой */}
                <div className="col-3 mt-2 mb-3">
                  <label className="switch-radio-wrap bold">
                    <input
                      type="checkbox"
                      name="condition_gear"
                      className="chech-dd"
                      checked={checkboxStates.condition_gear.includes("null")}
                      onChange={() => {
                        if (checkboxStates.condition_gear.includes("null")) {
                          // Если основной чекбокс был отмечен, очищаем массив и отключаем все чекбоксы
                          setCheckboxStates((prevState) => ({
                            ...prevState,
                            condition_gear: [],
                          }));
                        } else {
                          // Если основной чекбокс был снят, оставляем массив без изменений
                          setCheckboxStates((prevState) => ({
                            ...prevState,
                            condition_gear: ["null"],
                          }));
                        }
                      }}
                    />
                    <span className="switcher-radio"></span>
                    <span>Неисправности ходовой</span>
                  </label>
                  {checkboxStates.condition_gear.includes("null") && (
                    <div className="chech-dd-list active">
                      <label className="switch-radio-wrap d-flex mb-3">
                        <input
                          type="checkbox"
                          name="condition_gear"
                          defaultValue="Посторонний звук со стороны ходовой"
                          onChange={() =>
                            handleCheckboxChange(
                              "condition_gear",
                              "Посторонний звук со стороны ходовой"
                            )
                          }
                        />
                        <span className="switcher-radio ml-auto"></span>
                        <span className="check-box-text">
                          Посторонний звук со стороны ходовой
                        </span>
                      </label>
                      <label className="switch-radio-wrap d-flex mb-3">
                        <input
                          type="checkbox"
                          name="condition_gear"
                          defaultValue="Посторонние звуки при вращении рулевого колеса"
                          onChange={() =>
                            handleCheckboxChange(
                              "condition_gear",
                              "Посторонние звуки при вращении рулевого колеса"
                            )
                          }
                        />
                        <span className="switcher-radio ml-auto"></span>
                        <span className="check-box-text">
                          Посторонние звуки при вращении рулевого колеса
                        </span>
                      </label>
                    </div>
                  )}
                </div>
                {/*  */}
              </div>
            </div>
          </div>
        </div>
        {/*  */}

        {/* Фотографии */}
        <div className="inner-page__item">
          <ImageUploader />
        </div>
        {/*  */}


        {/* Документы */}
        <div className="inner-page__item">
          <FileUploadForm />
        </div>
        {/*  */}

        {/* Дополнительно */}
        <div className="inner-page__item">
          <div className="inner-item-title">Дополнительно</div>
          <div className="field-style">
            <span>Описание</span>
            <textarea
              name="car_additional"
              onChange={handleInputChange}
              placeholder="Не указан"
              className="mw-100"
            ></textarea>
          </div>
        </div>
        {/*  */}
      </div>
    </div>
  );
}

export default ApplicationCrtUptStep2;
