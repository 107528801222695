import axios from "axios";

const API_URL = "/v1/applications/";

// Application All LiST

const applicationCreateData = async (data) => {
  // Собираем элементы appData
  const {
    arriving_at,
    arriving_interval,
    courier_fullname,
    courier_phone,
    external_id,
    parking_id,
    partner_id,
    status_id,
  } = data;

  let app_data = {
    arriving_at,
    arriving_interval,
    courier_fullname,
    courier_phone,
    external_id,
    parking_id,
    partner_id,
    status_id,
  };
  //

  // Собираем элементы CarData
  const {
    vin_array,
    returned,
    license_plate,
    license_plate_status,
    vin_status,
    owner_number,
    color,
    sts,
    pts_provided,
    sts_provided,
    pts,
    pts_type,
    car_title,
    car_key_quantity,
    milage,
    car_additional,
    car_type_id,
    car_subtype_id,
    car_mark_id,
    car_model_id,
    year,
    car_generation_id,
    car_series_id,
    car_modification_id,
    car_engine_id,
    car_transmission_id,
    car_gear_id,
    condition_engine,
    condition_transmission,
    condition_electric,
    condition_gear,
  } = data;

  let car_data = {
    vin_array,
    license_plate,
    license_plate_status,
    owner_number,
    color,
    sts,
    pts_provided,
    sts_provided,
    pts,
    pts_type,
    car_title,
    car_key_quantity,
    milage,
    vin_status,
    car_additional,
    car_type_id,
    car_subtype_id,
    car_mark_id,
    car_model_id,
    year,
    car_generation_id,
    car_series_id,
    car_modification_id,
    car_engine_id,
    car_transmission_id,
    car_gear_id,
    condition_engine,
    condition_transmission,
    condition_electric,
    condition_gear,
    returned,
  };
  //

  // Собираем изображение
  const _token = data._token;
  const docs = data.docs;
  //

  let q = {
    app_data,
    car_data,
    _token,
    docs,
  };



  const response = await axios.post("/v1/applications/store", q, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
  return { data: response.data, status: response.status };
};

const applicationCreatePostService = {
  applicationCreateData,
};

export default applicationCreatePostService;
