import { configureStore } from "@reduxjs/toolkit";
import applicationTypeReducer from "./applications/applications-select/application-type/applicationTypeSlice";
import applicationBrandReducer from "./applications/applications-select/application-brand/applicationBrandSlice";
import applicationsReducer from "./applications/applicationsSlice";
import authReducer from "./auth/authSlice";
import applicationModelReducer from "./applications/applications-select/application-model/applicationModelSlice";
import applicationYearReducer from "./applications/applications-select/application-year/applicationYearSlice";
import applicationGenerationsReducer from "./applications/applications-select/application-generations/applicationGenerationsSlice";
import applicationsSingleReducer from "./applications/applications-single/applicationsSingleSlice";
import applicationSeriesReducer from "./applications/applications-select/application-series/applicationSeriesSlice";
import applicationModificationsReducer from "./applications/applications-select/applications-modification/applicationModificationsSlice";
import applicationEnginesReducer from "./applications/applications-select/applications-engines/applicationEnginesSlice";
import applicationTransmissionsReducer from "./applications/applications-select/applications-transmission/applicationTransmissionsSlice";
import applicationGearsReducer from "./applications/applications-select/applications-gears/applicationGearsSlice";
import applicationServiceReducer from './applications/application-service-order/applicationServiceOrderSlice'
import usersReducer from "./users/usersSlice";
import UserCreateReducer from "./users/user-create-slice/userCreateSlice";
import applicationDataReducer from "./applications/applications-create/application-create-collect-data/application-create-collect-data";
import applicationCreateReducer, {applicationCarTypes} from "./applications/applications-create/application-create-get/applicationCreateSlice";
import applicationCreatePostReducer from "./applications/applications-create/application-create-post/applicationCreatePostSlice";
import issueRequestCreateReducer from "./issueRequest/issue-request-create/IssueRequestSlice";
import userParkingReducer from "./users/user-parking/userParkingSlice";
import applicationsEditDataReducer from "./applications/applications-edit/applications-edit-get/applicationsEditSlice";
import userEditReducer from "./users/user-edit-slice/userEditSlice";
import childUserEditReducer from "./users/user-child-edit-slice/userChildSlice";
import reportsByPartnerAllReducer from "./reports/reportByPartnerSliceAll/reportsByPartnerSliceAll";
import reportsEmployeeReducer from "./reports/reportsEmployeeSlice/reportsEmployeeSlice";
import reportsByPartnerReducer from "./reports/reportsByPartnerSlice/reportsByPartnerSlice";
import reportByOrderReducer from "./reports/reportOrdersSlice/reportOrdersSlice"
import vinDublicateReducer from "./applications/application-vin-dublicate/applicationVinDublicateSlice";
import applicationGetMassImportedDataReducer from './applications/application-import/applicationImportSlice'

import legalReducer from "./legal/legalSlice";
import legalCreateReducer from "./legal/legal-create-slice/legalCreateSlice";
import legalEditReducer from "./legal/legal-edit-slice/legalEditSlice";

import parkingsReducer from "./parkings/parkingsSlice";
import parkingsCreateReducer from "./parkings/parkings-create-slice/parkingsCreateSlice";
import parkingsEditReducer from "./parkings/parkings-edit-slice/parkingsEditSlice";
import createFenceReducer from './parkings/create-fence/createFenceSlice'
import createCoverageReducer from './parkings/create-coverage/coverageCreateSlice'
import parkingEditPricesReducer from './parkings/parking-edit-prices-slice/parkingEditPricesSlice'
import ParkingEditServicesReducer from './parkings/parking-edit-services-slice/parkingEditServicesSlice'
import ParkingServiceCateReducer from './parkings/parking-services-create-slice/parkingServicesСreateSlice'
import getAllParkingsReducer from './parkings/get-all-parkings/getAllParkingsSlice'
import imageTemporaryParkingReducer from './image/image-temporary-parking/imageTemporaryParkingSlice'

import partnersReducer from "./partners/partnersSlise";

import IssueRequestEditReducer from "./issueRequest/issue-request-edit/IssueRequestEditSlice";
import partnersCreateReducer from "./partners/partners-create-slice/partnersCreateSlice";
import partnersEditReducer from "./partners/partners-edit-slice/partnersEditSlice";
import partnerSearchReducer from "./partners/partners-search-slice/partnerSearchSlice";
import partnersUserEditReducer from "./partners/partners-user-slice/partnerUserEditSlice";
import partnerModalInfoReducer, {partnerModalInfo} from "./partners/partner-get-modal-content-slice/partnersGetModalContentSlice";
import partnerToggleActiveReducer from './partners/partner-toggle-active/partnerToggleActiveSlice'

import legalsShowReducer from "./legal/legal-show/legalShowSlice";
import imageTemperaryReducer from "./image/image-temperaty/imageTemperarySlice";
import applicationLicenseDublicateReducer from "./applications/application-license-plate-dublicate/applicationLicenseDublicateSlice";
import applicationFilterReducer from "./applications/application-filter/applicationFilterSlice";
import applicationPhotoReducer from "./applications/application-photo/applicationPhotoSlice";
import applicationActReducer from "./applications/application-generate-act/applicationActSlice";
import applicationFavorityReducer from "./applications/application-favorite/applicationFavoritySlice";

import logReducer from "./logs/logsSliece";

import profileReducer from './profile/profile-get-update/profileGetUpdateSlice'

import permissionsReducer from "./permissions/permissionsSlice"

const store = configureStore({
  reducer: {
    // AUTH
    auth: authReducer,

    // APPLICATION
    application: applicationsReducer,
    applicationCreate: applicationCreateReducer,
    applicationCreateData: applicationDataReducer,
    applicationResultPost: applicationCreatePostReducer,
    applicationType: applicationTypeReducer,
    applicationBrand: applicationBrandReducer,
    applicationModel: applicationModelReducer,
    applicationYear: applicationYearReducer,
    applicationGenerations: applicationGenerationsReducer,
    applicationSeries: applicationSeriesReducer,
    applicationModifications: applicationModificationsReducer,
    applicationEngines: applicationEnginesReducer,
    applicationTransmissions: applicationTransmissionsReducer,
    applicationGears: applicationGearsReducer,
    applicationsEditData: applicationsEditDataReducer,
    applicationVinDublicate: vinDublicateReducer,
    applicationLicenseDublicate: applicationLicenseDublicateReducer,
    applicationFilter: applicationFilterReducer,
    applicationPhoto: applicationPhotoReducer,
    applicationAct: applicationActReducer,
    applicationFavorite: applicationFavorityReducer,
    applicationMassImportData : applicationGetMassImportedDataReducer,
    applicationService : applicationServiceReducer,

    // USERS
    users: usersReducer,
    usersCreate: UserCreateReducer,
    userEdit: userEditReducer,
    userChildEdit: childUserEditReducer,
    userParking: userParkingReducer,

    // APPLICATION SINGLE
    applicationSingle: applicationsSingleReducer,

    // ПОДАТЬ ЗАЯВКУ
    issueRequestCreate: issueRequestCreateReducer,
    issueRequestEdit: IssueRequestEditReducer,

    // ВЫГРУЗКИ
    reportsByPartner: reportsByPartnerReducer,
    reportsPartnersAll: reportsByPartnerAllReducer,
    reportsEmployee: reportsEmployeeReducer,
    reportsByOrder:reportByOrderReducer,

    // ЮРИДИЧЕСКИЕ ЛИЦА
    legal: legalReducer,
    legalCreate: legalCreateReducer,
    legalEdit: legalEditReducer,
    legalsShow: legalsShowReducer,

    // СТОЯНКИ
    parkings: parkingsReducer,
    parkingsCreate: parkingsCreateReducer,
    parkingsEdit: parkingsEditReducer,
    parkingPrices : parkingEditPricesReducer,
    parkingServices : ParkingEditServicesReducer,
    parkingAllServices : ParkingServiceCateReducer,

    // Покрытия и ограждения стоянок
    parkingFence : createFenceReducer,
    parkingCoverage : createCoverageReducer,

    //Получить все парковки

    parkingAll : getAllParkingsReducer,

    // Заказчики
    partners: partnersReducer,
    partnersCreate: partnersCreateReducer,
    partnersEdit: partnersEditReducer,
    partnerSearch: partnerSearchReducer,
    partnerModalInfo :partnerModalInfoReducer,
    partnerToggleActive :partnerToggleActiveReducer,

    // Заказчики ЮЗЕРЫ
    partnerUserEdit: partnersUserEditReducer,

    // ВРЕМЕННЫЕ ИЗОБРАЖЕНИЯ
    imageTemparary: imageTemperaryReducer,

    // Временное изображение для стоянки
    imageTemporaryParking: imageTemporaryParkingReducer,
    //ЛОГИ
    logs : logReducer,

    //ПРОФАЙЛ
    profile : profileReducer,

  //  Разрешения
    permissions : permissionsReducer
  },
});

export default store;
