import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import uploadImageService from "../uploadImageService";

const initialState = {
  image: [],
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
};

// Temparary image
export const imageTempararyData = createAsyncThunk(
  "application/imageTempararyData",
  async (token, thunkAPI) => {
    try {
      return await uploadImageService.imageTemparary(token);
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data) ||
        error.message ||
        error.toSrting();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
//

// Delete image
export const imageDelete = createAsyncThunk(
  "application/imageDelete",
  async (id, thunkAPI) => {
    try {
      return await uploadImageService.imageDelete(id);
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data) ||
        error.message ||
        error.toSrting();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
//

const imageTempararySlice = createSlice({
  name: "imageTempararySlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(imageTempararyData.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(imageTempararyData.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.image = action.payload;
      })
      .addCase(imageTempararyData.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.image = null;
      })
      .addCase(imageDelete.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(imageDelete.fulfilled, (state, action) => {
        state.image = state.image.filter((item) => item.id !== action.payload);
      })
      .addCase(imageDelete.rejected, (state, action) => {
        state.message = action.payload;
      });
  },
});

export default imageTempararySlice.reducer;
